@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600");
.steps .step-title {
  font-size: 14px !important;
  font-weight: 400 !important; }

.steps .step-item.is-completed.is-active::before,
.steps .step-item.is-completed.is-active::after {
  background: #dbdbdb  !important; }

.steps .step-marker {
  border: none !important;
  cursor: pointer;
  background: #F1F4F5 !important;
  color: #0BB4F5 !important;
  transition: .2s; }
  .steps .step-marker:hover {
    transform: scale(1.1); }

.steps .step-item.is-completed .step-marker {
  background: #0BB4F5 !important;
  color: #FAFAFA !important; }

#page-index .step-marker.is-completed {
  background: white !important;
  box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.2); }
  #page-index .step-marker.is-completed::after {
    position: absolute;
    background: transparent url("../images/1x/stepcheck.png") center center/contain no-repeat;
    content: "" !important;
    height: 20px !important;
    width: 20px !important;
    right: 5px !important;
    top: -8px !important;
    left: initial !important;
    right: -5px !important; }

#page-index .step-marker img {
  width: 25px; }

#page-index .view-payments-button {
  background: #dbdbdb;
  border: none; }
