.avatar {
  position: relative;
  display: flex;
  -webkit-touch-callout: none;
  user-select: none;
  text-align: center;
  align-items: center;
  justify-content: center; }
  .avatar img {
    display: block;
    border-radius: 100%; }
  .avatar:hover .overlay-change-avatar {
    opacity: 1; }
  .avatar .overlay-change-avatar {
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    border-radius: 150px;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: .3s;
    cursor: pointer; }
    .avatar .overlay-change-avatar svg {
      color: white;
      width: 34.48276px;
      height: 34.48276px; }

.cropavatar canvas {
  z-index: 9;
  box-shadow: 0px 0px 5px 1px gray;
  border-radius: 5px; }

.cropavatar .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 8; }
