@media (max-width: 768px) {
  html, body {
    height: 100%;
    width: 100%;
    overflow: auto; }
  html.has-modal #top-bar-menu {
    position: relative !important;
    z-index: 0;
    transition: 0s; }
  #root {
    /*position: fixed*/ }
  .top-alert {
    display: none; }
  #top-bar-menu {
    position: sticky;
    width: 100vw;
    z-index: 10;
    top: 0; }
    #top-bar-menu .navbar-menu {
      width: 70vw !important; }
      #top-bar-menu .navbar-menu span {
        padding-top: 5px !important; }
      #top-bar-menu .navbar-menu .navbar-menu-overlay {
        width: 30vw;
        height: 100vh;
        background: rgba(84, 93, 102, 0.86);
        position: fixed;
        z-index: 4;
        top: 65px;
        right: 0;
        backdrop-filter: blur(9px);
        -webkit-backdrop-filter: blur(9px); }
    #top-bar-menu .navbar-menu:not(.is-active) {
      left: -70vw;
      position: fixed;
      height: calc(100% - 65px);
      display: block !important; }
    #top-bar-menu .navbar-menu.is-active {
      left: 0vw;
      transition: 0.3s;
      position: fixed;
      height: calc(100% - 65px); }
    #top-bar-menu .navbar-burger {
      margin-left: unset !important; }
    #top-bar-menu .has-dropdown .navbar-item {
      padding-bottom: 0;
      padding-top: 1em; }
    #top-bar-menu .navbar-item {
      margin-left: auto;
      font-size: 1.2em; }
      #top-bar-menu .navbar-item .navbar-dropdown {
        font-size: 1em !important; }
      #top-bar-menu .navbar-item .navbar-dropdown:not(.is-active) {
        height: 0 !important;
        overflow: hidden;
        transition: 0.3s;
        padding-top: 0;
        padding-bottom: 0;
        border-top: 1px solid #d2d0d06e; }
      #top-bar-menu .navbar-item .navbar-dropdown.is-active {
        height: auto !important;
        transition: 0.3s;
        overflow: auto;
        margin-bottom: 19px;
        padding-left: 0px;
        padding-right: 0px;
        box-shadow: inset -1px 0px 8px 0px #d2d0d0ae; }
        #top-bar-menu .navbar-item .navbar-dropdown.is-active .navbar-item {
          font-size: 1em;
          padding-left: 50px; }
      #top-bar-menu .navbar-item span {
        padding-top: 5px !important;
        position: absolute; }
    #top-bar-menu .search-bar {
      display: none; }
    #top-bar-menu .navbar-dropdown a {
      font-size: 0.9em !important;
      padding-bottom: 0.5em !important;
      padding-top: 0.5em !important; }
  .section {
    max-height: calc(100vh - 375px); }
    .section .container .card-content {
      max-height: calc(100vh - 280px);
      overflow-y: auto; }
  .add-wallet-button {
    width: 48px !important;
    height: 48px !important;
    padding: 3px !important; }
  .toggle-wallet-view-mode {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .header-overview-wrapper {
    position: fixed; }
    .header-overview-wrapper .header-overview {
      width: 100vw;
      height: calc(100vh - 65px); }
  .page-login {
    background-color: white;
    width: 100vw; }
    .page-login .recaptcha-badge {
      position: absolute;
      width: 100vw; }
  #page-wallet .brand-currency {
    position: absolute;
    left: 0;
    margin-left: calc(50vw - 90px);
    top: -15px; }
  #page-settings .settings-view.has-active .container:not(.is-active) {
    display: none; }
  .page-cappay .container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh; }
  .page-cappay .is-responsive {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative; }
    .page-cappay .is-responsive td:empty:before {
      content: '\00a0'; }
    .page-cappay .is-responsive th,
    .page-cappay .is-responsive td {
      margin: 0;
      vertical-align: top; }
    .page-cappay .is-responsive th {
      text-align: left; }
    .page-cappay .is-responsive thead {
      border-right: solid 2px gray;
      display: block;
      float: left; }
      .page-cappay .is-responsive thead tr {
        display: block;
        padding: 0 10px 0 0; }
        .page-cappay .is-responsive thead tr th::before {
          content: "\00a0"; }
      .page-cappay .is-responsive thead td,
      .page-cappay .is-responsive thead th {
        border-width: 0 0 1px; }
    .page-cappay .is-responsive tbody {
      display: block;
      width: auto;
      position: relative;
      overflow-x: auto;
      white-space: nowrap; }
      .page-cappay .is-responsive tbody tr {
        display: inline-block;
        vertical-align: top; }
    .page-cappay .is-responsive th {
      display: block;
      text-align: right; }
    .page-cappay .is-responsive td {
      display: block;
      min-height: 1.25em;
      text-align: left; }
    .page-cappay .is-responsive th:last-child,
    .page-cappay .is-responsive td:last-child {
      border-bottom-width: 0; }
    .page-cappay .is-responsive tr:last-child td:not(:last-child) {
      border: 1px solid #444; }
    .page-cappay .is-responsive.is-bordered td,
    .page-cappay .is-responsive.is-bordered th {
      border-width: 1px; }
    .page-cappay .is-responsive.is-bordered tr td:last-child,
    .page-cappay .is-responsive.is-bordered tr th:last-child {
      border-bottom-width: 1px; }
    .page-cappay .is-responsive.is-bordered tr:last-child td,
    .page-cappay .is-responsive.is-bordered tr:last-child th {
      border-width: 1px; } }
