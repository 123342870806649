.wallet-selector-dropdown .wallets-dropdown-icon {
  right: 25px;
  position: absolute; }

.wallet-selector-dropdown button:not(.is-inverted):hover .wallets-dropdown-icon,
.wallet-selector-dropdown button:not(.is-inverted):focus .wallets-dropdown-icon {
  color: white; }

.wallet-selector-dropdown.invert-text-color button {
  border: 1px solid #dbdbdb !important;
  color: #dbdbdb !important; }

.wallet-selector-dropdown.invert-text-color button:not(.is-inverted):hover,
.wallet-selector-dropdown.invert-text-color button:not(.is-inverted):focus {
  border: 1px solid #f5f6f5 !important; }
  .wallet-selector-dropdown.invert-text-color button:not(.is-inverted):hover .wallets-dropdown-icon,
  .wallet-selector-dropdown.invert-text-color button:not(.is-inverted):focus .wallets-dropdown-icon {
    color: gray !important; }
