#page-api .box {
  padding-top: 64px; }
  #page-api .box .report-link {
    display: block;
    position: absolute;
    margin-top: -50px; }
  #page-api .box .blockquote {
    background: white; }
  #page-api .box .perm-item {
    background: whitesmoke;
    border-radius: 5px;
    margin: 3px;
    width: 30%; }
  #page-api .box .api-app-logo {
    width: 128px;
    height: 128px;
    position: absolute;
    margin-top: -120px;
    box-shadow: 0px 0px 3px #757575;
    border: 3px solid white; }

@media (max-width: 768px) {
  #page-api .box {
    overflow-y: auto !important;
    margin-top: 70px;
    max-height: 60vh; }
    #page-api .box .perm-item {
      width: 100% !important; } }

#page-dev .main-dev-panel {
  min-height: calc(100vh - 76px - 47px);
  margin-bottom: 0; }
  #page-dev .main-dev-panel .column-1 {
    background: #f1f1f1; }
  #page-dev .main-dev-panel .column-2 {
    background: #eceff3; }
  #page-dev .main-dev-panel .column-3 {
    background: #f4f6fb; }
