.caploading {
  background: rgba(0, 0, 0, 0.2) !important; }
  .caploading .title {
    margin-top: 0;
    background: white;
    padding: 2em 2em 1.5em 2em;
    box-shadow: 0px 0px 9px #999;
    border-radius: 9px; }

.caploading:after {
  display: none; }
