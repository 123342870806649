@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600");
.page-login * {
  font-weight: 300; }

.page-login .box {
  border-radius: 6px;
  box-shadow: none;
  padding: 25px; }

.page-login .button {
  border-radius: 45px;
  font-size: 16px;
  height: 47px;
  border: none; }
