.pins {
  display: flex;
  justify-content: space-around; }

.pin {
  height: 120px;
  width: 100px;
  background: #F7FAFA;
  border-radius: 3px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: .5s;
  border: none;
  text-align: center;
  font-size: 50px;
  color: #526069; }
