@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600");
.float-label-input {
  position: relative;
  display: inline-block;
  height: auto;
  width: 100%;
  background: transparent;
  z-index: 1; }
  .float-label-input.border-bottom-select {
    border-bottom: 2px solid rgba(84, 93, 102, 0.1); }
  .float-label-input.but-select {
    padding-right: 0 !important;
    padding: 0 6px !important; }
  .float-label-input * {
    color: #76838F;
    outline: none !important; }
  .float-label-input input {
    background: transparent;
    font-weight: 400;
    margin: 0;
    width: 100%;
    z-index: 2;
    font-size: 14px;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 10px 0px 3px 5px;
    border-bottom: 2px solid rgba(84, 93, 102, 0.1);
    transition: width 0.6s cubic-bezier(0.11, 0.78, 0.2, 0.93);
    transform: translateY(5px); }
    .float-label-input input:focus {
      border-color: #0BB4F5; }
    .float-label-input input.is-borderless {
      border-bottom: none;
      padding: 0; }
      .float-label-input input.is-borderless + label {
        top: 6px; }
      .float-label-input input.is-borderless:focus + label,
      .float-label-input input.is-borderless:valid + label,
      .float-label-input input.is-borderless.readonly + label {
        top: -10px !important;
        font-size: 12px; }
      .float-label-input input.is-borderless.readonly + label {
        top: -10px !important;
        font-size: 12px; }
  .float-label-input .keybd-button {
    position: absolute;
    transform: translate(-100%, -10%);
    border: none !important; }
  .float-label-input .select {
    width: 100%; }
    .float-label-input .select select {
      font-size: 16px;
      border: none;
      background: none;
      padding-left: 0;
      width: 100%;
      margin-top: 3px;
      padding-top: 11px;
      border-bottom: 2px solid rgba(84, 93, 102, 0.1); }
  .float-label-input label {
    font-weight: normal;
    margin: 0;
    font-weight: 400;
    position: absolute;
    color: #8F8F8F;
    left: 5px;
    cursor: initial;
    z-index: -1;
    top: -12px !important;
    font-size: 12px;
    color: #0BB4F5;
    font-weight: 400 !important;
    letter-spacing: .5px; }
  .float-label-input .select + label {
    top: -8px !important; }
  .float-label-input input:disabled + label {
    top: -6px !important; }
