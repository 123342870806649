.transaction-item {
  background: #F7FAFA;
  margin: 4px 0.75rem; }
  .transaction-item.is-white {
    background: white; }
  .transaction-item span {
    word-wrap: break-word; }
  .transaction-item .accumulated {
    color: #999; }
  .transaction-item .tx-id {
    float: right; }
  .transaction-item .config {
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: 0px;
    cursor: pointer; }
    .transaction-item .config:hover {
      color: #0BB4F5; }
  .transaction-item .values {
    text-align: right; }
  .transaction-item.is-goingout {
    border-right: 3px solid #FF5D66; }
    .transaction-item.is-goingout .amount {
      color: #FF5D66; }
  .transaction-item.is-entering {
    border-right: 3px solid #66CC99; }
    .transaction-item.is-entering .amount {
      color: #66CC99; }
  .transaction-item.is-neutral {
    border-right: 3px solid #e98406; }
    .transaction-item.is-neutral .amount {
      color: #e98406; }
  .transaction-item .tx-comments {
    word-wrap: break-word;
    hyphens: manual;
    font-style: italic;
    display: block; }
  .transaction-item .dropdown-menu {
    transform: translateX(-10rem); }

.pending-txes-notification {
  width: 97%;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #fbc888 !important;
  box-shadow: 0px 3px 0 0px #E3B884; }
  .pending-txes-notification .button {
    border: 1px solid #444;
    background-color: #fbc888; }

.back-from-pending {
  transition: 0.3s;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  cursor: pointer; }

.back-from-pending:hover {
  color: white;
  background: #444;
  box-shadow: 0px 2px 1px #444;
  transition: 0.3s;
  cursor: pointer; }
