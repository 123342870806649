@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600");
.top-alert {
  margin-bottom: 0 !important; }
  .top-alert .column {
    border: none !important; }
  .top-alert img {
    max-height: 130px; }

#top-bar-menu {
  background: white;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1); }
  #top-bar-menu .navbar-item {
    color: #76838F;
    cursor: pointer;
    padding: .3rem 0.5rem; }
    #top-bar-menu .navbar-item span {
      padding: 18px 0; }
    @media screen and (max-width: 1023px) {
      #top-bar-menu .navbar-item .icon {
        padding: 20px 0; } }
    #top-bar-menu .navbar-item .modal {
      cursor: auto; }
      #top-bar-menu .navbar-item .modal span {
        padding: 0; }
    #top-bar-menu .navbar-item img {
      max-height: none !important; }
    #top-bar-menu .navbar-item > img {
      max-height: none !important; }
  #top-bar-menu .search-bar {
    width: 300px;
    box-shadow: none;
    border: none;
    transition: .3s; }
    #top-bar-menu .search-bar:focus + .icon {
      color: #0BB4F5; }
  #top-bar-menu .navbar-dropdown {
    transform: translateY(10px); }
    #top-bar-menu .navbar-dropdown::before {
      top: -15px;
      left: 0;
      position: absolute;
      height: 20px;
      width: 100%;
      background: transparent;
      content: ""; }
    @media screen and (max-width: 1023px) {
      #top-bar-menu .navbar-dropdown {
        padding: 20px 30px;
        font-size: 20px; } }
  #top-bar-menu .navbar-brand .navbar-item:hover {
    background-color: #fff; }
  #top-bar-menu .badge.is-badge-left::after {
    transform: translate(10px, 20px); }
