@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600");
#page-transaction .wallet {
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }
  #page-transaction .wallet.is-personal {
    background-image: url("../images/SVG/wallet-personal.svg"); }
  #page-transaction .wallet.is-business {
    background-image: url("../images/SVG/wallet-business.svg"); }
  #page-transaction .wallet.is-anonymous {
    background-image: url("../images/SVG/wallet-anonymous.svg"); }
  #page-transaction .wallet.is-global {
    background-image: url("../images/SVG/wallet-global.svg"); }
  #page-transaction .wallet .inner {
    padding: 0 30px;
    max-width: 100%; }
    #page-transaction .wallet .inner * {
      color: inherit; }

#page-transaction .transaction-arrow {
  background: url("../images/SVG/transaction-arrow.svg") center center/contain no-repeat;
  width: 100%;
  height: 300px;
  position: relative; }
  #page-transaction .transaction-arrow:after {
    content: '';
    background: black;
    border-radius: 200px;
    width: 100px;
    height: 100px;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
  #page-transaction .transaction-arrow.is-btc:after {
    background-image: url("../images/SVG/coin_btc.svg"); }
  #page-transaction .transaction-arrow.is-brl:after {
    background-image: url("../images/SVG/coin_brl.svg"); }
  #page-transaction .transaction-arrow.is-eth:after {
    background-image: url("../images/SVG/coin_eth.svg"); }
  #page-transaction .transaction-arrow.is-eur:after {
    background-image: url("../images/SVG/coin_eur.svg"); }
  #page-transaction .transaction-arrow.is-jpy:after {
    background-image: url("../images/SVG/coin_jpy.svg"); }
  #page-transaction .transaction-arrow.is-gbp:after {
    background-image: url("../images/SVG/coin_gbp.svg"); }
  #page-transaction .transaction-arrow.is-dsh:after {
    background-image: url("../images/SVG/coin_dsh.svg"); }
  #page-transaction .transaction-arrow.is-ltc:after {
    background-image: url("../images/SVG/coin_ltc.svg"); }
  #page-transaction .transaction-arrow.is-usd:after {
    background-image: url("../images/SVG/coin_usd.svg"); }
  #page-transaction .transaction-arrow.is-bch:after {
    background-image: url("../images/SVG/coin_bch.svg"); }
  #page-transaction .transaction-arrow.is-xag:after {
    background-image: url("../images/SVG/coin_xag.svg"); }
  #page-transaction .transaction-arrow.is-xau:after {
    background-image: url("../images/SVG/coin_xau.svg"); }
  #page-transaction .transaction-arrow.is-eusdt:after {
    background-image: url("../images/SVG/coin_eusdt.svg"); }
  #page-transaction .transaction-arrow.is-bnb:after {
    background-image: url("../images/SVG/coin_eusdt.svg"); }
  #page-transaction .transaction-arrow.is-busd:after {
    background-image: url("../images/SVG/coin_busd.svg"); }
  #page-transaction .transaction-arrow.is-dai:after {
    background-image: url("../images/SVG/coin_dai.svg"); }
  #page-transaction .transaction-arrow.is-ampl:after {
    background-image: url("../images/SVG/coin_ampl.svg"); }
  #page-transaction .transaction-arrow.is-usdc:after {
    background-image: url("../images/SVG/coin_usdc.svg"); }
  #page-transaction .transaction-arrow.is-erd:after {
    background-image: url("../images/SVG/coin_erd.svg"); }
  #page-transaction .transaction-arrow.is-ada:after {
    background-image: url("../images/SVG/coin_ada.svg"); }
  #page-transaction .transaction-arrow.is-link:after {
    background-image: url("../images/SVG/coin_link.svg"); }
  #page-transaction .transaction-arrow.is-doge:after {
    background-image: url("../images/SVG/coin_doge.svg"); }
  #page-transaction .transaction-arrow.is-dot:after {
    background-image: url("../images/SVG/coin_dot.svg"); }
  #page-transaction .transaction-arrow.is-uni:after {
    background-image: url("../images/SVG/coin_uni.svg"); }
  #page-transaction .transaction-arrow.is-xrp:after {
    background-image: url("../images/SVG/coin_xrp.svg"); }
