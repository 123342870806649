.swicon .f-modal-icon {
  border-radius: 50%;
  border: 4px solid gray;
  box-sizing: content-box;
  height: 80px;
  margin: 20px auto;
  padding: 0;
  position: relative;
  width: 80px; }
  .swicon .f-modal-icon.f-modal-success, .swicon .f-modal-icon.f-modal-error {
    border-color: #A5DC86; }
  .swicon .f-modal-icon.f-modal-success:after, .swicon .f-modal-icon.f-modal-success:before {
    content: '';
    height: 120px;
    position: absolute;
    transform: rotate(45deg);
    width: 60px; }
  .swicon .f-modal-icon.f-modal-error:after, .swicon .f-modal-icon.f-modal-error:before {
    content: '';
    height: 120px;
    position: absolute;
    transform: rotate(45deg);
    width: 60px; }
  .swicon .f-modal-icon.f-modal-success:before, .swicon .f-modal-icon.f-modal-error:before {
    border-radius: 120px 0 0 120px;
    left: -33px;
    top: -7px;
    transform-origin: 60px 60px;
    transform: rotate(-45deg); }
  .swicon .f-modal-icon.f-modal-success:after, .swicon .f-modal-icon.f-modal-error:after {
    border-radius: 0 120px 120px 0;
    left: 30px;
    top: -11px;
    transform-origin: 0 60px;
    transform: rotate(-45deg); }
  .swicon .f-modal-icon.f-modal-success .f-modal-placeholder, .swicon .f-modal-icon.f-modal-error .f-modal-placeholder {
    border-radius: 50%;
    border: 4px solid rgba(165, 220, 134, 0.2);
    box-sizing: content-box;
    height: 80px;
    left: -4px;
    position: absolute;
    top: -4px;
    width: 80px;
    z-index: 2; }
  .swicon .f-modal-icon.f-modal-success .f-modal-fix, .swicon .f-modal-icon.f-modal-error .f-modal-fix {
    height: 90px;
    left: 28px;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 5px;
    z-index: 1; }
  .swicon .f-modal-icon.f-modal-success .f-modal-line, .swicon .f-modal-icon.f-modal-error .f-modal-line {
    background-color: #A5DC86;
    border-radius: 2px;
    display: block;
    height: 5px;
    position: absolute;
    z-index: 2; }
  .swicon .f-modal-icon.f-modal-success .f-modal-line.f-modal-tip, .swicon .f-modal-icon.f-modal-error .f-modal-line.f-modal-tip {
    left: 14px;
    top: 46px;
    transform: rotate(45deg);
    width: 25px; }
  .swicon .f-modal-icon.f-modal-success .f-modal-line.f-modal-long {
    right: 8px;
    top: 38px;
    transform: rotate(-45deg);
    width: 47px; }
  .swicon .f-modal-icon.f-modal-error {
    border-color: #F27474; }
    .swicon .f-modal-icon.f-modal-error .f-modal-line.f-modal-long {
      right: 8px;
      top: 38px;
      transform: rotate(-45deg);
      width: 47px; }
    .swicon .f-modal-icon.f-modal-error .f-modal-x-mark {
      display: block;
      position: relative;
      z-index: 2; }
    .swicon .f-modal-icon.f-modal-error .f-modal-placeholder {
      border: 4px solid rgba(200, 0, 0, 0.2); }
    .swicon .f-modal-icon.f-modal-error .f-modal-line {
      background-color: #F27474;
      top: 37px;
      width: 47px; }
      .swicon .f-modal-icon.f-modal-error .f-modal-line.f-modal-left {
        left: 17px;
        transform: rotate(45deg); }
      .swicon .f-modal-icon.f-modal-error .f-modal-line.f-modal-right {
        right: 16px;
        transform: rotate(-45deg); }
  .swicon .f-modal-icon.f-modal-warning {
    border-color: #F8BB86; }
    .swicon .f-modal-icon.f-modal-warning:before {
      animation: pulseWarning 2s linear infinite;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%; }
    .swicon .f-modal-icon.f-modal-warning:after {
      border-radius: 50%;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 1; }
    .swicon .f-modal-icon.f-modal-warning .f-modal-body {
      background-color: #F8BB86;
      border-radius: 2px;
      height: 47px;
      left: 50%;
      margin-left: -2px;
      position: absolute;
      top: 10px;
      width: 5px;
      z-index: 2; }
    .swicon .f-modal-icon.f-modal-warning .f-modal-dot {
      background-color: #F8BB86;
      border-radius: 50%;
      bottom: 10px;
      height: 7px;
      left: 50%;
      margin-left: -3px;
      position: absolute;
      width: 7px;
      z-index: 2; }
  .swicon .f-modal-icon + .f-modal-icon {
    margin-top: 50px; }

.animateSuccessTip {
  animation: animateSuccessTip .75s; }

.animateSuccessLong {
  animation: animateSuccessLong .75s; }

.f-modal-icon.f-modal-success.animate:after, .f-modal-icon.f-modal-error.animate:after {
  animation: rotatePlaceholder 4.25s ease-in; }

.animateErrorIcon {
  animation: animateErrorIcon .5s; }

.animateXLeft {
  animation: animateXLeft .75s; }

.animateXRight {
  animation: animateXRight .75s; }

.scaleWarning {
  animation: scaleWarning 0.75s infinite alternate; }

.pulseWarningIns {
  animation: pulseWarningIns 0.75s infinite alternate; }

@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg); }
  100%, 12% {
    transform: rotate(-405deg); } }

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0deg);
    opacity: 1; } }

@keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0; }
  84% {
    left: 14px;
    top: 33px;
    width: 47px; }
  100% {
    left: 17px;
    top: 37px;
    width: 47px; } }

@keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0; }
  84% {
    right: 14px;
    top: 33px;
    width: 47px; }
  100% {
    right: 16px;
    top: 37px;
    width: 47px; } }

@keyframes scaleWarning {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1.02); }
  100% {
    transform: scale(1); } }

@keyframes pulseWarning {
  0% {
    transform: scale(1);
    opacity: 0.5; }
  30% {
    transform: scale(1);
    opacity: 0.5; }
  100% {
    background-color: #F8BB86;
    transform: scale(2);
    opacity: 0; } }

@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }
  100% {
    background-color: #F8BB86; } }
